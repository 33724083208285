import React, { useEffect, useState } from "react"
import SVGIcon from "../common/SVGIcon"
import { pageScroller, scrollPosition } from "../utility/scroll-position"

let IntegrationDropDown = props => {
  const [scrollValue, setScrollValue] = useState()
  const { allTags } = props
  const PSA_RMM = ["RMM", "PSA"]
  const [screenWidth, setScreenWidth] = useState(0)
  const [psarmmDrpDown, setPsaRmmDrpDown] = useState({ PSA: false, RMM: false })

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    let arr = [1,2]
    arr.map((item)=>{
      const dropdownDiv = document.querySelector(`#sub_tag_${item}`)
    if (dropdownDiv) {
      const toggledNavbar = document
        .querySelector(`#inner_subtag_${item}`)
        ?.getBoundingClientRect()
        dropdownDiv.style.height = `${toggledNavbar.height}px`
    }
    })
    
  })

  useEffect(() => {
    setScrollValue(
      scrollPosition(
        ".track-category",
        screenWidth < 500 ? ".brand-nav-light-mob" : ".brand-nav-light",
        screenWidth < 500 ? -10 : 40
      )
    )
  }, [screenWidth])

  const PsaRmmDropdownFn = item => {
    let tempObj = { ...psarmmDrpDown }
    if (item === "PSA") {
      if (tempObj["RMM"]) {
        tempObj["RMM"] = false
      }
      tempObj["PSA"] = !tempObj["PSA"]
    } else {
      if (tempObj["PSA"]) {
        tempObj["PSA"] = false
      }
      tempObj["RMM"] = !tempObj["RMM"]
    }
    setPsaRmmDrpDown(tempObj)
  }

  const closeAllDropdowns = () => {
    let tempObj = { ...psarmmDrpDown }
    tempObj["PSA"] = false
    tempObj["RMM"] = false
    setPsaRmmDrpDown(tempObj)
  }

  return (
    <div className="categories">
      <div className="taglist-desk p16">
        <div className="alt">
          <p className="font-roboto fw-600 mb48">CATEGORIES</p>
          <div
            className={`tagName cursor-pointer ${
              props.currentTag === "all" ? "active text-pink" : ""
            }`}
          >
            <p
              onClick={() => {
                props.selectTag("all")
                pageScroller(scrollValue)
              }}
              className="font-roboto fw-500"
            >
              All
            </p>
          </div>
          <div>
            {PSA_RMM.map((item,id) => {
              return (
                <>
                  <div
                    className={`d-flex justify-content-between align-items-center cursor-pointer mb24 mt40 ${
                      psarmmDrpDown[item] ? "underline" : ""
                    }`}
                    onClick={() => PsaRmmDropdownFn(item)}
                  >
                    <p className="font-roboto fw-600 m-0">{item}</p>
                    <SVGIcon
                      name="arrow-down"
                      className={`down-arrow ${
                        psarmmDrpDown[item] ? "active" : ""
                      }`}
                    />
                  </div>
                  {
                    <div
                      id={`sub_tag_${id+1}`}
                      className={`sub-tags ${
                        psarmmDrpDown[item] ? "" : "dspnone"
                      }`}
                    >
                      <div id={`inner_subtag_${id+1}`}>
                        {allTags.map(tag => {
                          return (
                            tag.name !== "coming soon" &&
                            tag.slug === item && (
                              <div
                                className={`tagName cursor-pointer ${
                                  props.currentTag === tag.name ? "active" : ""
                                }`}
                              >
                                <p
                                  onClick={() => {
                                    props.selectTag(tag.name)
                                    pageScroller(scrollValue)
                                    screenWidth < 500 && closeAllDropdowns()
                                  }}
                                >
                                  {tag.name}
                                </p>
                              </div>
                            )
                          )
                        })}
                      </div>
                    </div>
                  }
                </>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default IntegrationDropDown
