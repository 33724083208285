import { StaticQuery, graphql } from "gatsby"
import React from "react"
import { Col, Row } from "react-bootstrap"
import Card from "./card"

let integrationHomeCard = props => {
  function createCards(dt, st) {
    let cardArray = dt.SuperOps.seoPosts.map(integ => {
      const { tag, referenceTag, logo, slug, availability } = integ
      if (availability === st) {
        return props.category && props.category === "all" ? (
          <Col lg={3} className="col-6">
            <Card
              tag={referenceTag.name}
              linkName={tag}
              link={slug}
              logo={logo.url}
              currentState={availability}
            />
          </Col>
        ) : (
          tag === props.category && (
            <Col lg={3} className="col-6">
              <Card
                tag={referenceTag.name}
                linkName={tag}
                link={slug}
                logo={logo.url}
                currentState={availability}
              />
            </Col>
          )
        )
      }
    })

    return cardArray
  }

  return (
    <StaticQuery
      query={graphql`
        query IntegrationsHomeCards {
          SuperOps {
            seoPosts(where: { pageName: Integrations }) {
              tag
              referenceTag {
                ... on SuperOps_Tag {
                  name
                }
              }
              slug
              logo {
                url(transformation: { document: { output: { format: webp } } })
              }
              availability
            }
          }
        }
      `}
      render={data => (
        <Row>
          {createCards(data, "available")}
          {createCards(data, "coming_soon")}
        </Row>
      )}
    />
  )
}

export default integrationHomeCard
