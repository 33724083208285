import { graphql, Link, StaticQuery } from "gatsby"
import React, { useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"
import Buttons from "../../components/common/button"
import CarouselComponent from "../../components/common/carousel-component"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import SVGIcon from "../../components/common/SVGIcon"
import IntegrationsHomeCards from "../../components/integrations/integration-home-card"
import IntegrationDropDown from "../../components/integrations/integrations-drop-down"
import Navigation from "../../components/navigation"
import "../../styles/pages/marketplace.scss"

let Integration = props => {
  let [currentCategory, setCurrentCategory] = useState("all")

  const FeatIntegration = ({ integ }) => {
    return integ.map(item => {
      return (
        <Link
          to={`/${item.slug}`}
          className="integ-box d-flex flex-column justify-content-between position-relative"
        >
          <SVGIcon
            name="marketplace/badgeStar"
            className="badge-star position-absolute"
          />
          <div className="img-wrapper mx-auto">
            <img src={item.logo.url} className="w-100 img" />
          </div>
          <div className="ref-tag text-center">
            <p className="p16 m-0">{item.tag}</p>
          </div>
        </Link>
      )
    })
  }

  return (
    <div className="integration">
      <StaticQuery
        query={graphql`
          query IntegrationHome {
            SuperOps {
              pages(where: { title: "Marketplace-new" }) {
                id
                title
                linkTitle
                newField {
                  ... on SuperOps_Hero {
                    tag
                    heroHeading: heading {
                      text
                    }
                    content {
                      text
                    }
                    primaryButtonText
                    primaryButtonLink
                    secondaryButtonText
                    secondaryButtonLink
                  }
                  ... on SuperOps_FaqComponent {
                    __typename
                    heading
                    description
                    faqs {
                      question
                      answerrt {
                        html
                      }
                    }
                  }
                  ... on SuperOps_CtaFeature {
                    __typename
                    ctaBox {
                      type
                      theme
                      customBackgroundColor
                      heading {
                        html
                      }
                      description {
                        html
                      }
                      primaryButtonText
                      primaryButtonLink
                      secondaryButtonText
                      secondaryButtonLink
                    }
                  }
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
              }
              seoPosts(where: { pageName: Integrations }) {
                tag
                featuredPosition
                referenceTag {
                  ... on SuperOps_Tag {
                    name
                  }
                }
                slug
                logo {
                  url(
                    transformation: { document: { output: { format: webp } } }
                  )
                }
                availability
              }
              tags(where: { pageName: Integrations },orderBy: order_ASC) {
                name
                slug
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { seo, newField, linkTitle } = page
              const Hero = newField[0]
              const Faq = newField[1]
              const CtaBox = newField[2]
              const Integrations = data.SuperOps.seoPosts
              const FeaturedIntegrations = Integrations.filter(
                x => x.featuredPosition
              ).sort((a, b) => a.featuredPosition - b.featuredPosition)

              return (
                <>
                  <Frame seo={seo} newButton>
                    <header>
                      <Navigation page="Home" newButton />
                    </header>

                    <section className="hero">
                      <Container
                        className={`Layout-container position-relative`}
                      >
                        <SVGIcon
                          name="leftHand"
                          className="position-absolute left-image d-dsk down-to-up-1"
                        />
                        <SVGIcon
                          name="rightHand"
                          className="position-absolute right-image d-dsk down-to-up-1"
                        />
                        <p
                          className={`text-pink mb24 down-to-up-1 tag`}
                        >
                          {Hero.tag}
                        </p>
                        <h1 className="font-roboto heading fw-bold mx-auto mb50 down-to-up-2">
                          {Hero.heroHeading.text}
                        </h1>
                        <p className="p18 description mx-auto mb48 down-to-up-3">
                          {Hero.content.text}
                        </p>
                        <div className="d-flex gap-16 justify-content-center flex-wrap down-to-up-4">
                          <Buttons
                            theme="primary-new"
                            link={Hero.primaryButtonLink}
                            text={Hero.primaryButtonText}
                          />
                          <Buttons
                            theme={`secondary-new`}
                            link={Hero.secondaryButtonLink}
                            text={Hero.secondaryButtonText}
                          />
                        </div>
                      </Container>
                    </section>

                    <div className="overall-integ">
                      <section className="featured-integ">
                          <Container className="Layout-container down-to-up-5">
                            <h3 className="font-roboto text-center fw-bold mb50">
                              {linkTitle[0]}
                            </h3>

                            <div className="d-flex justify-content-between d-dsk">
                              <FeatIntegration integ={FeaturedIntegrations} />
                            </div>
                            <div className="d-mob">
                              <CarouselComponent
                                status
                                swipeable
                                items={1}
                                autoPlay={false}
                              >
                                {/* <FeatIntegration integ={FeaturedIntegrations} /> */}
                                {FeaturedIntegrations.map(item => {
                                  return (
                                    <Link
                                      to={`/${item.slug}`}
                                      className="integ-box d-flex flex-column justify-content-between position-relative"
                                    >
                                      <SVGIcon
                                        name="marketplace/badgeStar"
                                        className="badge-star position-absolute"
                                      />
                                      <div className="img-wrapper mx-auto">
                                        <img
                                          src={item.logo.url}
                                          className="w-100 img"
                                        />
                                      </div>
                                      <div className="ref-tag text-center">
                                        <p className="p16 m-0">
                                          {item.referenceTag.name}
                                        </p>
                                      </div>
                                    </Link>
                                  )
                                })}
                              </CarouselComponent>
                            </div>
                          </Container>
                      </section>

                      <Fade duration={1000}>
                        <section className="content mt60">
                          <Container className="track-category Layout-container">
                            <Row>
                              <Col lg={4} className="left sticky-top">
                                <div className="sticky-top">
                                  <IntegrationDropDown
                                    currentTag={currentCategory}
                                    selectTag={category =>
                                      setCurrentCategory(category)
                                    }
                                    allTags={data.SuperOps.tags}
                                  />
                                </div>
                              </Col>
                              <Col className="right" lg={8}>
                                <IntegrationsHomeCards
                                  category={currentCategory}
                                />
                              </Col>
                            </Row>
                          </Container>
                        </section>
                      </Fade>
                    </div>

                    {/* <section className="faq down-to-up-4">
                      <Container className="Layout-container">
                        <Row className="justify-content-between">
                          <Col lg={4} className="left">
                            <Fade duration={1000}>
                              <h3 className="heading fw-bold font-roboto">
                                {Faq.heading}
                              </h3>
                              <div className="p16">
                                <p className="description mb16">
                                  {Faq.description}
                                </p>
                              </div>
                            </Fade>
                          </Col>
                          <Col lg={8} className="right">
                            <div className="section">
                              <CustomAccordion items={Faq.faqs} />
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </section> */}

                    <section>
                      <Fade duration={1000}>
                        <CTA data={[CtaBox.ctaBox]} newButton newDesign />
                      </Fade>
                    </section>
                  </Frame>
                </>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default Integration
