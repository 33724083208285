import { Link } from "gatsby"
import React from "react"
import "../../styles/component/integration-card.scss"
import SVGIcon from "../common/SVGIcon"

let IntegrationCard = props => {
  const { tag, logo, link, linkName } = props
  return (
    <Link className="tdnone" to={`/${link || "signup"}`}>
      <div className="integration-card position-relative d-flex flex-column justify-content-between">
        <SVGIcon
          name="marketplace/plugInOut"
          className="plug-in-out position-absolute"
        />
        <div className="img-wrapper mx-auto">
          <img
            src={logo}
            alt={link.slice(link.indexOf("/") + 1)}
            className="w-100"
          />
        </div>
        <div className="text-center ref-tag">
          <p className="p12 m-0">{tag === "others" ? "PSA" : tag}</p>
        </div>
      </div>
    </Link>
  )
}

export default IntegrationCard
